'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import { Badge } from '@zealy/design-system';
import { GetQuestboardOutput } from '@zealy/utils';

import { ModuleSection } from './ModuleSection';

export const SectionView = ({
  questboard,
  isSubscriptionLimitReached,
}: {
  questboard: GetQuestboardOutput;
  isSubscriptionLimitReached?: boolean;
}) => {
  const t = useTranslations('questboard');

  const indexLockedStart = questboard?.findIndex(module => module.status === 'locked');
  const indexCompletedStart = questboard?.findIndex(module => module.status === 'completed');

  return (
    <div className="flex flex-col">
      {questboard?.map((module, index) => (
        <>
          {indexCompletedStart === index && (
            <Badge className="z-[1] bg-secondary -mb-150 mx-auto" variant="disabled">
              {t('modules-section', {
                status: t('badge.statuses.completed'),
              })}
            </Badge>
          )}
          {indexLockedStart === index && (
            <Badge className="z-[1] bg-secondary -mb-150 mx-auto" variant="disabled">
              {t('modules-section', {
                status: t('badge.statuses.lock'),
              })}
            </Badge>
          )}
          <ModuleSection
            key={module.id}
            module={module}
            isSubscriptionLimitReached={isSubscriptionLimitReached}
          />
        </>
      ))}
    </div>
  );
};
