import type { CSSProperties } from 'react';
import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useParams, usePathname } from 'next/navigation';

import type { GetQuestboardOutput } from '@zealy/utils';
import { Badge, ProgressBar } from '@zealy/design-system';
import { CheckCircleFilled, SnowflakeLine } from '@zealy/icons';

import { QuestCard } from '#components/QuestCard';
import { DEFAULT_THEME } from '#constants/questboard';
import { cn } from '#utils/utils';

import { hexToRgbString } from '../GridView/ModuleCard/ModuleCard.utils';

const moduleHeaderStyle = {
  backgroundImage: `linear-gradient(to bottom left, #00000000, var(--bg) 80%), var(--cover)`,
  minHeight: '238px',
};

const fallbackHeaderStyle = {
  backgroundImage: 'var(--cover)',
};

const ModuleHeader = ({
  module,
  defaultTheme,
  description,
  isSubscriptionLimitReached,
}: {
  module: GetQuestboardOutput[number];
  description?: string;
  isSubscriptionLimitReached?: boolean;
  defaultTheme?: boolean;
}) => {
  const t = useTranslations('questboard.badge.statuses');

  return (
    <div
      style={
        isSubscriptionLimitReached
          ? undefined
          : !defaultTheme
          ? moduleHeaderStyle
          : fallbackHeaderStyle
      }
      className={cn(
        'grid lg:grid-cols-2 py-400 rounded-t-md px-600 xl:px-1200 bg-no-repeat bg-center bg-cover items-end',
        '-mb-400 pb-1000',
        'ring-4 ring-[var(--color-bg-secondary)]',
        isSubscriptionLimitReached ? 'bg-tertiary' : '',
      )}
    >
      <div className="flex flex-col gap-150">
        <p className="module-card-quest-module-name text-primary">
          <span>{module.title}</span>
          {module.status === 'completed' && (
            <Badge className="inline-flex ml-150" icon={<CheckCircleFilled />}>
              {t('completed')}
            </Badge>
          )}
          {module.status !== 'completed' && isSubscriptionLimitReached && (
            <Badge className="inline-flex ml-150" icon={<SnowflakeLine />} variant="info">
              {t('limit-reached')}
            </Badge>
          )}
        </p>

        {description && <p className="body-paragraph-lg mb-100 text-secondary">{description}</p>}

        {module.status !== 'completed' && module.totalQuestCount > 0 && (
          <ProgressBar
            variant="neutral"
            value={module.claimedQuestCount}
            max={module.totalQuestCount}
            fillShadowBg="var(--bg)"
          />
        )}
      </div>
    </div>
  );
};

export const ModuleSection = ({
  module,
  isSubscriptionLimitReached,
}: {
  module: GetQuestboardOutput[number];
  isSubscriptionLimitReached?: boolean;
}) => {
  const isXl = useRef(
    typeof window !== 'undefined' && window.matchMedia('(min-width: 1280px)').matches,
  );
  const pathname = usePathname();

  const baseUrl = `${pathname.substring(0, pathname.indexOf('/questboard'))}/questboard`;

  const shouldUseFallback = !module.coverUrl && !module.color;

  const fallbackTheme = shouldUseFallback
    ? DEFAULT_THEME[module.position % DEFAULT_THEME.length]
    : undefined;

  const coverUrl = module.coverUrl
    ? module.coverUrl
    : isXl.current
    ? fallbackTheme?.coverUrlXL.src
    : fallbackTheme?.coverUrl.src;

  const style = useMemo(
    () =>
      ({
        '--bg':
          !!module.color || !!module.coverUrl ? module.color || 'var(--color-bg-tertiary)' : '',
        '--module-color': module.color
          ? hexToRgbString(module.color)
          : fallbackTheme?.color ?? '96,47,214',
        '--cover': `url(${coverUrl})`,
      } as CSSProperties),
    [module.color, module.coverUrl, coverUrl, fallbackTheme?.color],
  );

  return (
    <div
      style={style}
      className={clsx('rounded-t-md', module.status === 'locked' ? 'grayscale' : '')}
    >
      <ModuleHeader
        module={module}
        defaultTheme={shouldUseFallback}
        isSubscriptionLimitReached={isSubscriptionLimitReached}
      />
      {module.status !== 'completed' && (
        <div className="rounded-t-md bg-secondary grid lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-400 py-400 w-full px-200 md:px-600 xl:px-1200 md:py-800">
          {module.quests.map(quest => (
            <QuestCard
              key={quest.id}
              href={`${baseUrl}/${module.id}/${quest.id}`}
              quest={quest}
              isSubscriptionLimitReached={isSubscriptionLimitReached}
            />
          ))}
        </div>
      )}
    </div>
  );
};
